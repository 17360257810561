const CopyLinkIcon = (props) => {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 800 800"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Path"
        fill="#000000"
        stroke="none"
        d="M 0 636.799988 C 0 676.266663 13.866667 709.866659 41.600002 737.599976 C 69.333337 765.333293 102.933339 779.199951 142.400009 779.199951 L 616 779.199951 C 654.93335 779.199951 688.266683 765.333293 716 737.599976 C 743.733358 709.866659 757.600037 676.266663 757.600037 636.799988 L 757.600037 432.000031 L 663.200012 511.200012 L 663.200012 636.799988 C 663.200012 650.133321 658.666667 661.333333 649.599976 670.400024 C 640.533325 679.466675 629.333333 684.266663 616 684.799988 L 142.400009 684.799988 C 129.066666 684.799988 117.866664 680 108.800003 670.400024 C 99.733337 660.800008 95.200005 649.599996 95.200005 636.799988 L 95.200005 163.200012 C 95.200005 150.400004 99.733337 139.199992 108.800003 129.599976 C 117.866664 120 129.066666 115.466675 142.400009 116 L 379.200012 116 L 379.200012 21.599976 L 142.400009 21.599976 C 102.933339 21.599976 69.333337 35.466654 41.600002 63.200012 C 13.866667 90.933329 0 124.266663 0 163.200012 L 0 636.799988 Z M 142.400009 495.200012 C 142.400009 541.066671 151.46667 585.06665 169.599991 627.199951 C 178.133326 578.133301 196.799998 533.599976 225.600006 493.599976 C 254.399994 453.599996 290.133331 421.333333 332.800018 396.799988 C 375.466665 372.266663 422.399994 358.400004 473.600006 355.200012 L 473.600006 495.200012 L 757.600037 258.400024 L 473.600006 21.599976 L 473.600006 163.200012 C 428.800018 163.200012 385.866679 172 344.799988 189.599976 C 303.733337 207.199992 268.53334 230.933329 239.199997 260.799988 C 209.866664 290.666667 186.133331 325.866669 168 366.399994 C 149.866669 406.933319 141.333338 449.866659 142.400009 495.200012 Z M 800 552 L 800 552 L 800 552 L 800 552 Z"
      />
    </svg>
  );
};

export default CopyLinkIcon;
